/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    FormLabel,
    useToast,
    Radio, 
    RadioGroup,
    Select,
  } from '@chakra-ui/react';
  // Custom components
  
  import Card from 'components/card/Card';
  import InputField from 'components/fields/InputField';
  import TextField from 'components/fields/TextField';
  import React, { useState,useEffect } from 'react';
  import Dropzone from 'react-dropzone';
  import axios from 'axios';
  import { API_BASE_URL } from 'constants/apiConstants';
  import { ASSETS_URL } from 'constants/apiConstants';
  import { useParams,useNavigate } from 'react-router-dom';
  
  export default function EditBroker() {
    const navigate = useNavigate();
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [activeBullets, setActiveBullets] = useState({
      user: true,
      address: false,
    });
    const [input,setInput] = useState([]);
    const [errors, setErrors] = useState({});
    const toast = useToast();
    const [brokerId, setBrokerId] = useState('');
    const [gender, setGender] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const { id } = useParams();
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setInput({
        ...input,
        [name]: value,
      });
      if((name === 'username' || name === 'email') && value != ''){
        const payload = {
          "name":name,
          "value":value,
          "brokerId": brokerId
        }
        axios.post(API_BASE_URL+'check/value',payload,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
        })
        .then(function (response){
          if(response.data.apistatus === false){
            toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
            if(name === 'username'){
              setInput({
                'username': '',
              });
            }else if(name === 'email'){
              setInput({
                'email': '',
              });
            }
          }
        })
        .catch(function (error){
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
      }
    };
  
    const handleGenderChange = (value) => {
      setGender(value);
    };
  
    const validateForm = (data) => {
      const errors = {};
      
      if (!data.first_name) {
        errors.first_name = 'First name field is required';
      }
  
      if (!data.last_name) {
        errors.last_name = 'Last name field is required';
      }
      
      if (!data.username) {
        errors.username = 'Username field is required';
      }
  
      if (!data.email) {
        errors.email = 'Email field is required';
      }
  
      if (!gender) {
        errors.gender = 'Gender field is required';
      }
  
      if (!data.phone_number) {
        errors.phone_number = 'Phone number field is required';
      }
  
      if (!data.website) {
        errors.website = 'Website field is required';
      }
  
      if (!data.brief_bio) {
        errors.brief_bio = 'Brief bio field is required';
      }

      if (!data.verified_type) {
        errors.verified_type = 'This field is required';
      }
      return errors;
    };
  
    const handleSubmitPropertyInfo = (e) => {
      e.preventDefault();
      const newErrors = validateForm(input);
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        const payload = {
          "first_name":input.first_name,
          "last_name":input.last_name,
          "username":input.username,
          "email":input.email,
          "gender":gender,
          "phone_number":input.phone_number,
          "website":input.website,
          "verified_type":input.verified_type,
          "brief_bio":input.brief_bio,
          "brokerId": brokerId
        }
        axios.post(API_BASE_URL+'broker/store',payload,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
        })
        .then(function (response){
          if(response.data.apistatus === true){
            setBrokerId(response.data.data.brokerId)
            toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
            addressTab.current.click();
          }else if(response.data.apistatus === false){
            toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
          }
        })
        .catch(function (error){
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
      }
    };
  
    const handleProfileImageUpload = (acceptedFiles) => {
      if(brokerId === ''){
        toast({ title: 'Please fill broker info details first.',status: 'error',isClosable: true,position: 'top'})
        return false;
      }
      const formData = new FormData();
      formData.append("profileImage",acceptedFiles[0]);
      formData.append("broker_id",brokerId);
      axios.post(API_BASE_URL+'broker/upload/profile-image',formData,{
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then(function (response){
        if(response.data.apistatus === true){
          setProfileImage(response.data.data)
          toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
        }else if(response.data.apistatus === false){
          toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
        }
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }
  
    const removeImage = (type,image) => {
      const payload = {
          'broker_id': brokerId,
          'type': type,
          'image': image
      }
      axios.post(API_BASE_URL+'delete/broker-profile-image',payload,{
          headers: {
              Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
      })
      .then(function (response){
          if(type === 1){
              setProfileImage(null)
          }
      })
      .catch((error) => {
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    };

    const getBroker = (e) => {
        axios.get(API_BASE_URL+'get/broker/'+id,{
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
            }
        })
        .then(function (response){
            setBrokerId(response.data.data.id);
            setInput({
              first_name: response.data.data.first_name,
              last_name: response.data.data.last_name,
              username: response.data.data.user_name,
              email: response.data.data.email,
              phone_number: response.data.data.phone_number,
              website: response.data.data.website,
              verified_type: response.data.data.is_verified,
              brief_bio: response.data.data.brief_bio,
            });
            setGender(response.data.data.gender);
            setProfileImage(response.data.data.profile_picture);
        }) 
        .catch((error) => {
            toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
    }

    const redirectToListing = (e) => {
        navigate("/admin/broker/overview");
    }
  
    useEffect(() => {
        getBroker();
    },[])
  
    const userTab = React.useRef();
    const addressTab = React.useRef();
  
    return (
      <Flex
          direction="column"
          minH="100vh"
          align="center"
          pt={{ sm: '125px', lg: '75px' }}
          position="relative"
        >
          <Box
            h="45vh"
            bgGradient="linear(to-b, brand.400, brand.600)"
            position="absolute"
            w="100%"
            borderRadius="20px"
          />
    
          <Tabs
            variant="unstyled"
            zIndex="0"
            mt={{ base: '60px', md: '165px' }}
            display="flex"
            flexDirection="column"
          >
            <TabList
              display="flex"
              alignItems="center"
              alignSelf="center"
              justifySelf="center"
            >
              <Tab
                _focus={{ border: '0px', boxShadow: 'unset' }}
                ref={userTab}
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    user: true,
                    address: false,
                    profile: false,
                  })
                }
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                  _before={{
                    content: "''",
                    width: { sm: '120px', md: '250px', lg: '300px' },
                    height: '3px',
                    bg: activeBullets.address ? 'white' : 'brand.400',
                    left: { sm: '12px', md: '30px' },
                    top: {
                      sm: activeBullets.user ? '6px' : '4px',
                      md: null,
                    },
                    position: 'absolute',
                    bottom: activeBullets.user ? '40px' : '38px',
    
                    transition: 'all .3s ease',
                  }}
                >
                  <Box
                    zIndex="1"
                    border="2px solid"
                    borderColor={activeBullets.user ? 'white' : 'brand.400'}
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    w="16px"
                    h="16px"
                    mb="8px"
                    borderRadius="50%"
                  />
                  <Text
                    color={activeBullets.user ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.user ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    Broker Info
                  </Text>
                </Flex>
              </Tab>
              <Tab
                _focus={{ border: '0px', boxShadow: 'unset' }}
                ref={addressTab}
                w={{ sm: '120px', md: '250px', lg: '300px' }}
                onClick={() =>
                  setActiveBullets({
                    user: true,
                    address: true,
                    profile: false,
                  })
                }
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                  _before={{
                    content: "''",
                    width: { sm: '120px', md: '250px', lg: '300px' },
                    height: '3px',
                    bg: activeBullets.profile ? 'white' : 'brand.400',
                    left: { sm: '12px', md: '32px' },
                    top: '6px',
                    position: 'absolute',
                    bottom: activeBullets.address ? '40px' : '38px',
    
                    transition: 'all .3s ease',
                  }}
                >
                  <Box
                    zIndex="1"
                    border="2px solid"
                    borderColor={activeBullets.address ? 'white' : 'brand.400'}
                    bgGradient="linear(to-b, brand.400, brand.600)"
                    w="16px"
                    h="16px"
                    mb="8px"
                    borderRadius="50%"
                  />
                  <Text
                    color={activeBullets.address ? 'white' : 'gray.300'}
                    fontWeight={activeBullets.address ? 'bold' : 'normal'}
                    display={{ sm: 'none', md: 'block' }}
                  >
                    Image
                  </Text>
                </Flex>
              </Tab>
            </TabList>
            <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
              <TabPanel
                w={{ sm: '330px', md: '700px', lg: '850px' }}
                p="0px"
                mx="auto"
              >
                <Card p="30px">
                  <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Broker Info
                  </Text>
                  <form onSubmit={handleSubmitPropertyInfo}>
                    <Flex direction="column" w="100%">
                        <Stack direction="column" spacing="20px">
                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                <Stack direction="column" spacing="20px" margin="20px 0px">
                                  <Flex direction="column">
                                    <InputField
                                        mb="0px"
                                        id="first-name"
                                        placeholder="eg. Esthera"
                                        label="First Name"
                                        name="first_name"
                                        onChange={handleChange}
                                        value={input.first_name}
                                    />
                                    {errors.first_name &&
                                      <span className="error-message">
                                          {errors.first_name}
                                      </span>
                                    }
                                  </Flex>
                                </Stack>
  
                                <Stack direction="column" spacing="20px" margin="20px 0px">
                                  <Flex direction="column">
                                    <InputField
                                        mb="0px"
                                        id="last-name"
                                        placeholder="eg. Peterson"
                                        label="Last Name"
                                        name="last_name"
                                        onChange={handleChange}
                                        value={input.last_name}
                                    />
                                    {errors.last_name &&
                                      <span className="error-message">
                                          {errors.last_name}
                                      </span>
                                    }
                                  </Flex>
                                </Stack>
  
                                <Stack direction="column" spacing="20px" margin="20px 0px">
                                  <Flex direction="column">
                                    <InputField
                                        mb="0px"
                                        id="username"
                                        placeholder="eg. Simmmple"
                                        label="Username"
                                        name="username"
                                        onChange={handleChange}
                                        value={input.username}
                                    />
                                    {errors.username &&
                                      <span className="error-message">
                                          {errors.username}
                                      </span>
                                    }
                                  </Flex>
                                </Stack>
  
                                <Stack direction="column" spacing="20px" margin="20px 0px">
                                  <Flex direction="column">
                                    <InputField
                                        mb="0px"
                                        id="email"
                                        placeholder="eg. test@gmail.com"
                                        label="Email"
                                        name="email"
                                        onChange={handleChange}
                                        value={input.email}
                                    />
                                    {errors.email &&
                                      <span className="error-message">
                                          {errors.email}
                                      </span>
                                    }
                                  </Flex>
                                </Stack>
  
                                <Stack direction="column" spacing="20px" margin="20px 0px">
                                  <Flex direction="column">
                                    <FormLabel
                                      ms="10px"
                                      htmlFor="broker"
                                      fontSize="sm"
                                      color=''
                                      fontWeight="bold"
                                      _hover={{ cursor: 'pointer' }}
                                    >
                                      Gender
                                    </FormLabel>
  
                                    <RadioGroup onChange={handleGenderChange} value={gender} name='gender'>
                                      <Stack direction='row'>
                                        <Radio value='1'>Male</Radio>
                                        <Radio value='2'>Female</Radio>
                                      </Stack>
                                    </RadioGroup>
                                    {errors.gender &&
                                      <span className="error-message">
                                          {errors.gender}
                                      </span>
                                    }
                                  </Flex>
                                </Stack>
  
                                <Stack direction="column" spacing="20px" margin="20px 0px">
                                  <Flex direction="column">
                                    <InputField
                                        mb="0px"
                                        id="phone-number"
                                        placeholder="4030120241"
                                        label="Phone Number"
                                        name="phone_number"
                                        onChange={handleChange}
                                        value={input.phone_number}
                                    />
                                    {errors.phone_number &&
                                      <span className="error-message">
                                          {errors.phone_number}
                                      </span>
                                    }
                                  </Flex>
                                </Stack>

                                <Stack direction="column" spacing="20px" margin="20px 0px">
                                  <Flex direction="column">
                                    <InputField
                                        id="website"
                                        placeholder="Description Title"
                                        label="Website"
                                        name="website"
                                        mb="0px"
                                        onChange={handleChange}
                                        value={input.website}
                                    />
                                    {errors.website &&
                                      <span className="error-message">
                                          {errors.website }
                                      </span>
                                    }
                                  </Flex>
                                </Stack>

                                <Stack direction="column" spacing="20px" margin="20px 0px">
                                  <Flex direction="column">
                                    <FormLabel
                                      ms="10px"
                                      htmlFor="verified-type"
                                      fontSize="sm"
                                      color=''
                                      fontWeight="bold"
                                      _hover={{ cursor: 'pointer' }}
                                    >
                                      Is Verified
                                    </FormLabel>
            
                                    <Select
                                      id="verified-type"
                                      value={input.verified_type}
                                      name="verified_type"
                                      onChange={handleChange}
                                      borderRadius="16px"
                                    >
                                      <option value=''>Select...</option>
                                      <option value="1">Yes</option >
                                      <option value="0">No</option >
                                    </Select>
                                    {errors.verified_type &&
                                      <span className="error-message">
                                          {errors.verified_type}
                                      </span>
                                    }
                                  </Flex>
                                </Stack>    
                            </SimpleGrid>
                        </Stack>
                        <Stack direction="column" spacing="20px" margin="20px 0px">
                            <Flex direction="column">
                              <TextField
                                  minH="200px"
                                  id="brief_bio"
                                  placeholder="Enter a few words about property"
                                  label="Brief bio"
                                  name="brief_bio"
                                  onChange={handleChange}
                                  value={input.brief_bio}
                              />
                              {errors.brief_bio &&
                                <span className="error-message">
                                    {errors.brief_bio }
                                </span>
                              }
                            </Flex>
                        </Stack>
                        <Flex justify="space-between" mt="24px">
                          <Button
                            variant="darkBrand"
                            fontSize="sm"
                            borderRadius="16px"
                            w={{ base: '128px', md: '148px' }}
                            h="46px"
                            ms="auto"
                            type='submit'
                            // onClick={() => addressTab.current.click()}
                          >
                            Next
                          </Button>
                        </Flex>
                    </Flex>
                  </form>
                </Card>
              </TabPanel>
              <TabPanel
                w={{ sm: '330px', md: '700px', lg: '850px' }}
                p="0px"
                mx="auto"
              >
                <Card p="30px">
                    <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                        Media
                    </Text>
                    <Flex direction="column" w="100%">
                        <FormLabel
                        ms="10px"
                        htmlFor="property_logo"
                        fontSize="sm"
                        color=''
                        fontWeight="bold"
                        _hover={{ cursor: 'pointer' }}
                        >
                        Profile Image
                        </FormLabel>

                        <Dropzone onDrop={handleProfileImageUpload} accept="image/*" maxFiles={1} minH={200}>
                        {({getRootProps, getInputProps, isDragActive, isDragAccept,isDragReject }) => {
                            const additionalClass = isDragAccept ? "accept" :isDragReject ? "reject" : "";

                            return (
                            <div
                            {...getRootProps({
                                className: `dropzone ${additionalClass}`
                            })}
                            >
                                <input {...getInputProps()} />
                                <p>Drag'n'drop images,or click to select files</p>
                            </div>
                            );
                        }}
                        </Dropzone>
                        {profileImage && (
                            <div className='new_box'>
                            <img src={ASSETS_URL+'broker/profile/'+profileImage} className='img-container logo-image' alt='upload profile file' width="50%"/>
                            <button className='remove-image' onClick={() => removeImage(1,profileImage)}>X</button>
                            </div>
                        )}
                    </Flex>
                    <Flex justify="space-between" mt="24px">
                        <Button
                            variant="darkBrand"
                            fontSize="sm"
                            borderRadius="16px"
                            w={{ base: '128px', md: '148px' }}
                            h="46px"
                            ms="auto"
                            type='submit'
                            onClick={() => redirectToListing()}
                        >
                        Save
                        </Button>
                    </Flex>
                </Card>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
    );
  }
  