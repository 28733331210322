export const columnsDataBusinessOverview = [
    {
      Header: "ACTIONS",
      accessor: "business_id",
    },
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Business Image",
      accessor: "profile_picture",
    },
    {
      Header: "Title", 
      accessor: "user_name",
    },
    {
      Header: "Created At",
      accessor: "date",
    }
  ];
  