// Chakra imports
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  FormLabel,
  useToast,
  Select
} from '@chakra-ui/react';
// Custom components

import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import React, { useState,useEffect } from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { API_BASE_URL } from 'constants/apiConstants';
import { ASSETS_URL } from 'constants/apiConstants';
import { useParams,useNavigate } from 'react-router-dom';

export default function EditBusiness() {
  const navigate = useNavigate();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [activeBullets, setActiveBullets] = useState({
    user: true,
    address: false,
  });
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const [businessImage, setBusinessImage] = useState('');
  const [businessId, setBusinessId] = useState('');
  const { id } = useParams();
  const [input,setInput] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [standoutAmenities, setStandoutAmenities] = useState([]);

  const getBusinessTypes = (e) => {
    axios.get(API_BASE_URL+'get/business/types',{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
    })
    .then(function (response){
      setBusinessTypes(response.data.data);
    }) 
    .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  };

  const getStandoutAmenities = () => {
    axios.get(API_BASE_URL+'get/standout/amenity',{
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      }
    })
    .then((response) => {
      setStandoutAmenities(response.data.data)
    })
    .catch((error) => {
      toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  };

  const getBusiness = (e) => {
    axios.get(API_BASE_URL+'get/business/'+id,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
    })
    .then(function (response){
        setBusinessId(response.data.data.id);
        setInput({
          business_name: response.data.data.name,
          username: response.data.data.user_name,
          email: response.data.data.email,
          phone_number: response.data.data.phone_number,
          website: response.data.data.website,
          verified_type: response.data.data.is_verified,
          brief_bio: response.data.data.brief_bio,
        });
        setBusinessImage(response.data.data.profile_picture);
    }) 
    .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
    if((name === 'username' || name === 'email') && value != ''){
      const payload = {
        "name":name,
        "value":value,
        "businessId": businessId
      }
      axios.post(API_BASE_URL+'check/value',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then(function (response){
        if(response.data.apistatus === false){
          toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
          if(name === 'username'){
            setInput({
              'username': '',
            });
          }else if(name === 'email'){
            setInput({
              'email': '',
            });
          }
        }
      })
      .catch(function (error){
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.business_name) {
      errors.business_name = 'This field is required';
    }
    
    if (!data.username) {
      errors.username = 'Username field is required';
    }

    if (!data.verified_type) {
      errors.verified_type = 'This field is required';
    }
    return errors;
  };

  const handleSubmitBusinessInfo = (e) => {
    e.preventDefault();
    const newErrors = validateForm(input);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const payload = {
        "business_name":input.business_name,
        "username":input.username,
        "email":input.email,
        "phone_number":input.phone_number,
        "verified_type":input.verified_type,
        "website":input.website,
        "brief_bio":input.brief_bio,
        "businessId": businessId
      }
      axios.post(API_BASE_URL+'business/store',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then(function (response){
        if(response.data.apistatus === true){
          toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
          addressTab.current.click();
        }else if(response.data.apistatus === false){
          toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
        }
      })
      .catch(function (error){
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }
  };

  const handleBusinessImageUpload = (acceptedFiles) => {
    if(acceptedFiles[0].type !== 'image/png' && acceptedFiles[0].type !== 'image/jpg' && acceptedFiles[0].type !== 'image/jpeg'){
      toast({ title: 'File type not supported. We accept JPG, PNG, JPEG only.',status: 'error',isClosable: true,position: 'top'})
      return false;
    }
    
    if(businessId === ''){
      toast({ title: 'Please fill business info details first.',status: 'error',isClosable: true,position: 'top'})
      return false;
    }

    const formData = new FormData();
    formData.append("businessImage",acceptedFiles[0]);
    formData.append("business_id",businessId);
    axios.post(API_BASE_URL+'business/upload/image',formData,{
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      }
    })
    .then(function (response){
      if(response.data.apistatus === true){
        setBusinessImage(response.data.data)
        toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
      }else if(response.data.apistatus === false){
        toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
      }
    })
    .catch((error) => {
      toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  }

  const removeImage = (type,image) => {
    const payload = {
        'business_id': businessId,
        'type': type,
        'image': image
    }
    axios.post(API_BASE_URL+'delete/business-image',payload,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
    })
    .then(function (response){
        if(type === 1){
            setBusinessImage(null)
        }
    })
    .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  };

  const redirectToListing = (e) => {
    if(businessId === ''){
      toast({ title: 'Please fill business info details first.',status: 'error',isClosable: true,position: 'top'})
      return false;
    }
    navigate("/admin/business/overview");
  }

  useEffect(() => {
    getBusiness();
    getBusinessTypes();
    getStandoutAmenities();
  },[])

  const userTab = React.useRef();
  const addressTab = React.useRef();

  return (
    <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: '125px', lg: '75px' }}
        position="relative"
      >
        <Box
          h="45vh"
          bgGradient="linear(to-b, brand.400, brand.600)"
          position="absolute"
          w="100%"
          borderRadius="20px"
        />
  
        <Tabs
          variant="unstyled"
          zIndex="0"
          mt={{ base: '60px', md: '165px' }}
          display="flex"
          flexDirection="column"
        >
          <TabList
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifySelf="center"
          >
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={userTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  user: true,
                  address: false,
                  profile: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.address ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '30px' },
                  top: {
                    sm: activeBullets.user ? '6px' : '4px',
                    md: null,
                  },
                  position: 'absolute',
                  bottom: activeBullets.user ? '40px' : '38px',
  
                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.user ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.user ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.user ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Business Info
                </Text>
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={addressTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  user: true,
                  address: true,
                  profile: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.profile ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '32px' },
                  top: '6px',
                  position: 'absolute',
                  bottom: activeBullets.address ? '40px' : '38px',
                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.address ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.address ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.address ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Image
                </Text>
              </Flex>
            </Tab>
          </TabList>
          <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Business Info
                </Text>
                <form onSubmit={handleSubmitBusinessInfo}>
                  <Flex direction="column" w="100%">
                      <Stack direction="column" spacing="20px">
                          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">

                                <Flex direction="column">
                                  <InputField
                                      mb="0px"
                                      id="username"
                                      placeholder="eg. Simmmple"
                                      label="Title *"
                                      name="username"
                                      onChange={handleChange}
                                      value={input.username}
                                  />
                                  {errors.username &&
                                    <span className="error-message">
                                        {errors.username}
                                    </span>
                                  }
                                </Flex>


                                <Flex direction="column">
                                  <InputField
                                      mb="0px"
                                      id="business-name"
                                      placeholder="eg. Esthera"
                                      label="Business Name *"
                                      name="business_name"
                                      onChange={handleChange}
                                      value={input.business_name}
                                      FormLabel
                                  />
                                  {errors.business_name &&
                                    <span className="error-message">
                                        {errors.business_name}
                                    </span>
                                  }
                                </Flex>

                                <Flex direction="column">
                                  <InputField
                                      mb="0px"
                                      id="email"
                                      placeholder="eg. test@gmail.com"
                                      label="Contact Email"
                                      name="email"
                                      onChange={handleChange}
                                      value={input.email}
                                  />
                                  {errors.email &&
                                    <span className="error-message">
                                        {errors.email}
                                    </span>
                                  }
                                </Flex>

                                <Flex direction="column">
                                  <InputField
                                      mb="0px"
                                      id="phone-number"
                                      placeholder="4030120241"
                                      label="Phone Number"
                                      name="phone_number"
                                      onChange={handleChange}
                                      value={input.phone_number}
                                  />
                                  {errors.phone_number &&
                                    <span className="error-message">
                                        {errors.phone_number}
                                    </span>
                                  }
                                </Flex>

                                <Flex direction="column">
                                  <InputField
                                      id="website"
                                      placeholder="Description Title"
                                      label="Website"
                                      name="website"
                                      mb="0px"
                                      onChange={handleChange}
                                      value={input.website}
                                  />
                                  {errors.website &&
                                    <span className="error-message">
                                        {errors.website }
                                    </span>
                                  }
                                </Flex>

                                <Flex direction="column">
                                  <FormLabel
                                    ms="10px"
                                    htmlFor="verified-type"
                                    fontSize="sm"
                                    color=''
                                    fontWeight="bold"
                                    _hover={{ cursor: 'pointer' }}
                                  >
                                    Is Verified *
                                  </FormLabel>
          
                                  <Select
                                    id="verified-type"
                                    value={input.verified_type}
                                    name="verified_type"
                                    onChange={handleChange}
                                    borderRadius="16px"
                                  >
                                    <option value=''>Select...</option>
                                    <option value="1">Yes</option >
                                    <option value="2">No</option >
                                  </Select>
                                  {errors.verified_type &&
                                    <span className="error-message">
                                        {errors.verified_type}
                                    </span>
                                  }
                                </Flex>
                          </SimpleGrid>
                      </Stack>
                      <Stack direction="column" spacing="20px" margin="20px 0px">
                          <Flex direction="column">
                            <TextField
                                minH="200px"
                                id="brief_bio"
                                placeholder="Enter a few words about property"
                                label="Brief bio"
                                name="brief_bio"
                                onChange={handleChange}
                                value={input.brief_bio}
                            />
                            {errors.brief_bio &&
                              <span className="error-message">
                                  {errors.brief_bio }
                              </span>
                            }
                          </Flex>
                      </Stack>
                      <Flex justify="space-between" mt="24px">
                        <Button
                          variant="darkBrand"
                          fontSize="sm"
                          borderRadius="16px"
                          w={{ base: '128px', md: '148px' }}
                          h="46px"
                          ms="auto"
                          type='submit'
                          // onClick={() => addressTab.current.click()}
                        >
                          Next
                        </Button>
                      </Flex>
                  </Flex>
                </form>
              </Card>
            </TabPanel>
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Media
                </Text>
                <Flex direction="column" w="100%">
                  <FormLabel
                    ms="10px"
                    htmlFor="property_logo"
                    fontSize="sm"
                    color=''
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                  >
                    Profile Image
                  </FormLabel>

                  <Dropzone onDrop={handleBusinessImageUpload} accept="image/*" maxFiles={1} minH={200}>
                    {({getRootProps, getInputProps, isDragActive, isDragAccept,isDragReject }) => {
                      const additionalClass = isDragAccept ? "accept" :isDragReject ? "reject" : "";

                      return (
                        <div
                        {...getRootProps({
                          className: `dropzone ${additionalClass}`
                        })}
                        >
                          <input {...getInputProps()} />
                          <p>Drag'n'drop images,or click to select files</p>
                        </div>
                      );
                    }}
                  </Dropzone>
                  {businessImage && (
                      <div className='new_box'>
                        <img src={ASSETS_URL+'business/'+businessImage} className='img-container logo-image' alt='upload profile file' width="50%"/>
                        <button className='remove-image' onClick={() => removeImage(1,businessImage)}>X</button>
                      </div>
                  )}
                </Flex> 

                <Flex justify="space-between" mt="24px">
                  <Button
                      variant="darkBrand"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      ms="auto"
                      type='submit'
                      onClick={() => redirectToListing()}
                  >
                  Save
                  </Button>
                </Flex>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
  );
}
